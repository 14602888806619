<template>
	<div class="main">
		<Top />
		<div style="display: flex" class="container">
			<Menu :index="menuIndex" />
			<el-main>
				<el-tabs>
					<el-tab-pane label="系统管理">
						<el-row>
							<el-col :span="24">
								<div style="max-width: 100%">
									<el-button type="primary" @click="on_modify_data_click" :disabled="!this.init" :loading="this.btn_saving">提交更改</el-button>

									<el-tabs type="border-card">
										<el-tab-pane label="基础配置">
											<el-form ref="form" v-model="system_config_data" label-width="200px">
												<el-form-item label="升级状态">
													<el-checkbox v-model="system_config_data.upgrade">开始升级</el-checkbox>
												</el-form-item>
												<el-form-item label="灰度方式">
													<el-select v-model="system_config_data.grayType">
														<el-option :value="0" label="随机灰度"></el-option>
														<el-option :value="1" label="全量到site-a"></el-option>
														<el-option :value="2" label="全量到site-b"></el-option>
														<el-option :value="3" label="指定用户ID到site-b"></el-option>
														<el-option :value="4" label="指定版本到site-b"></el-option>
													</el-select>
												</el-form-item>
												<el-form-item label="灰度比例" v-if="system_config_data.grayType === 0">
													<el-input v-model="system_config_data.grayRadio"></el-input>
												</el-form-item>
												<el-form-item label="灰度用户ID" v-if="system_config_data.grayType === 3">
													<el-form :inline="true" v-model="search" class="demo-form-inline">
														<el-form-item>
															<el-button type="primary" @click="show_create_dialog(1)">添加</el-button>
														</el-form-item>
														<el-form-item label="搜索">
															<el-input v-model="search.grayUserIds" placeholder="请输入用户ID" style="width: 200px" @input="on_key_word_change"></el-input>
														</el-form-item>
													</el-form>
													<el-table :data="show_table_grayUserIds" style="width: 100%">
														<el-table-column label="操作" width="120">
															<template slot-scope="scope">
																<el-button @click.native.prevent="deleteRow(scope.$index, system_config_data.grayUserIds)" type="text" size="small">移除</el-button>
															</template>
														</el-table-column>
														<el-table-column prop="userId" label="用户ID" style="width: 100%"></el-table-column>
													</el-table>
												</el-form-item>
												<el-form-item label="灰度版本" v-if="system_config_data.grayType === 4">
													<el-form :inline="true" v-model="search" class="demo-form-inline">
														<el-form-item>
															<el-button type="primary" @click="show_create_dialog(2)">添加</el-button>
														</el-form-item>
														<el-form-item label="搜索">
															<el-input v-model="search.grayVersions" placeholder="请输入版本号、名称、应用ID搜索" style="width: 500px" @input="on_key_word_change"></el-input>
														</el-form-item>
													</el-form>
													<el-table :data="show_table_grayVersions" style="width: 100%">
														<el-table-column label="操作" width="120">
															<template slot-scope="scope">
																<el-button @click.native.prevent="deleteRow(scope.$index, system_config_data.grayVersions)" type="text" size="small">移除</el-button>
															</template>
														</el-table-column>
														<el-table-column prop="appId" label="应用ID" width="100"></el-table-column>
														<el-table-column prop="version" label="内部版本号" width="100"></el-table-column>
														<el-table-column prop="clientType" label="客户端类型" width="100"></el-table-column>
														<el-table-column prop="name" label="名称"></el-table-column>
													</el-table>
												</el-form-item>
											</el-form>
										</el-tab-pane>
										<el-tab-pane label="访问频率配置">
											<el-form ref="form" v-model="system_config_data" label-width="200px">
												<el-form-item label="默认Ip访问速率（分钟）">
													<el-input v-model="system_config_data.maxIpRequestRate"></el-input>
												</el-form-item>
												<el-form-item label="默认Uri访问速率（分钟）">
													<el-input v-model="system_config_data.maxUriRequestRate"></el-input>
												</el-form-item>
											</el-form>
										</el-tab-pane>
										<el-tab-pane label="App版本拦截控制">
											<el-form :inline="true" v-model="search" class="demo-form-inline">
												<el-form-item>
													<el-button type="primary" @click="show_create_dialog(0)">添加</el-button>
												</el-form-item>
												<el-form-item label="搜索">
													<el-input v-model="search.appVersionFilters" placeholder="请输入搜索地址" style="width: 500px" @input="on_key_word_change"></el-input>
												</el-form-item>
											</el-form>
											<el-table :data="show_table_appVersionFilters" style="width: 100%">
												<el-table-column label="操作" width="120">
													<template slot-scope="scope">
														<el-button @click.native.prevent="deleteRow(scope.$index, system_config_data.appVersionFilters)" type="text" size="small">移除</el-button>
													</template>
												</el-table-column>
												<el-table-column prop="appId" label="应用ID" width="180"></el-table-column>
												<el-table-column prop="clientType" label="客户端类型" width="180"></el-table-column>
												<el-table-column prop="min" label="最小版本号" width="180"></el-table-column>
												<el-table-column prop="name" label="版本名称" style="width: 100%"></el-table-column>
											</el-table>
										</el-tab-pane>
										<el-tab-pane label="Uri 频率控制">
											<el-form :inline="true" v-model="search" class="demo-form-inline">
												<el-form-item>
													<el-button type="primary" @click="show_create_dialog(3)">添加</el-button>
												</el-form-item>
												<el-form-item label="搜索">
													<el-input v-model="search.uriRequestRateConfig" placeholder="请输入搜索地址" style="width: 500px" @input="on_key_word_change"></el-input>
												</el-form-item>
											</el-form>
											<el-table :data="show_table_uriRequestRateConfig" style="width: 100%">
												<el-table-column label="操作" width="120">
													<template slot-scope="scope">
														<el-button @click.native.prevent="deleteRow(scope.$index, system_config_data.uriRequestRateConfig)" type="text" size="small">移除</el-button>
													</template>
												</el-table-column>
												<el-table-column prop="rate" label="频率" width="180"></el-table-column>
												<el-table-column prop="uri" label="Uri" style="width: 100%"></el-table-column>
											</el-table>
										</el-tab-pane>
										<el-tab-pane label="IP 白名单">
											<el-form :inline="true" v-model="search" class="demo-form-inline">
												<el-form-item>
													<el-button type="primary" @click="show_create_dialog(4)">添加</el-button>
												</el-form-item>
												<el-form-item label="搜索">
													<el-input v-model="search.ipWhiteList" placeholder="请输入搜索IP" style="width: 500px" @input="on_key_word_change"></el-input>
												</el-form-item>
											</el-form>
											<el-table :data="show_table_ipWhiteList" style="width: 100%">
												<el-table-column label="操作" width="120">
													<template slot-scope="scope">
														<el-button @click.native.prevent="deleteRow(scope.$index, system_config_data.ipWhiteList)" type="text" size="small">移除</el-button>
													</template>
												</el-table-column>
												<el-table-column label="IP" prop="ip" style="width: 100%"></el-table-column>
											</el-table>
										</el-tab-pane>
										<el-tab-pane label="IP 黑名单">
											<el-form :inline="true" v-model="search" class="demo-form-inline">
												<el-form-item>
													<el-button type="primary" @click="show_create_dialog(5)">添加</el-button>
												</el-form-item>
												<el-form-item label="搜索">
													<el-input v-model="search.ipBlackList" placeholder="请输入搜索IP" style="width: 500px" @input="on_key_word_change"></el-input>
												</el-form-item>
											</el-form>
											<el-table :data="show_table_ipBlackList" style="width: 100%">
												<el-table-column label="操作" width="120">
													<template slot-scope="scope">
														<el-button @click.native.prevent="deleteRow(scope.$index, system_config_data.ipBlackList)" type="text" size="small">移除</el-button>
													</template>
												</el-table-column>
												<el-table-column label="IP" prop="ip" style="width: 100%"></el-table-column>
											</el-table>
										</el-tab-pane>
										<el-tab-pane label="Uri 白名单">
											<el-form :inline="true" v-model="search" class="demo-form-inline">
												<el-form-item>
													<el-button type="primary" @click="show_create_dialog(6)">添加</el-button>
												</el-form-item>
												<el-form-item label="搜索">
													<el-input v-model="search.uriWhiteList" placeholder="请输入搜索Uri" style="width: 500px" @input="on_key_word_change"></el-input>
												</el-form-item>
											</el-form>
											<el-table :data="show_table_uriWhiteList" style="width: 100%">
												<el-table-column label="操作" width="120">
													<template slot-scope="scope">
														<el-button @click.native.prevent="deleteRow(scope.$index, system_config_data.uriWhiteList)" type="text" size="small">移除</el-button>
													</template>
												</el-table-column>
												<el-table-column label="Uri" prop="uri" style="width: 100%"></el-table-column>
											</el-table>
										</el-tab-pane>
										<el-tab-pane label="Uri 黑名单">
											<el-form :inline="true" v-model="search" class="demo-form-inline">
												<el-form-item>
													<el-button type="primary" @click="show_create_dialog(7)">添加</el-button>
												</el-form-item>
												<el-form-item label="搜索">
													<el-input v-model="search.uriBlackList" placeholder="请输入搜索Uri" style="width: 500px" @input="on_key_word_change"></el-input>
												</el-form-item>
											</el-form>
											<el-table :data="show_table_uriBlackList" style="width: 100%">
												<el-table-column label="操作" width="120">
													<template slot-scope="scope">
														<el-button @click.native.prevent="deleteRow(scope.$index, system_config_data.uriBlackList)" type="text" size="small">移除</el-button>
													</template>
												</el-table-column>
												<el-table-column label="Uri" prop="uri" style="width: 100%"></el-table-column>
											</el-table>
										</el-tab-pane>
									</el-tabs>
								</div>
							</el-col>
						</el-row>
					</el-tab-pane>
				</el-tabs>
			</el-main>
		</div>
		<el-dialog :visible.sync="dialog_visible" center :title="create_data.title" width="400px" :close-on-click-modal="false" :close-on-press-escape="false">
			<el-form :model="create_data" label-position="right" label-width="100px">
				<el-form-item label="IP" v-if="create_data.type === 4 || create_data.type === 5">
					<el-input v-model="create_data.ip"></el-input>
				</el-form-item>
				<el-form-item label="Uri" v-if="create_data.type === 3 || create_data.type === 6 || create_data.type === 7">
					<el-input v-model="create_data.uri"></el-input>
				</el-form-item>
				<el-form-item label="频率" v-if="create_data.type === 3">
					<el-input v-model="create_data.rate"></el-input>
				</el-form-item>
				<el-form-item label="用户ID" v-if="create_data.type === 1">
					<el-input v-model="create_data.userId"></el-input>
				</el-form-item>
				<el-form-item label="应用ID" v-if="create_data.type === 0 || create_data.type === 2">
					<el-input v-model="create_data.appId"></el-input>
				</el-form-item>
				<el-form-item label="版本名称" v-if="create_data.type === 0 || create_data.type === 2">
					<el-input v-model="create_data.name"></el-input>
				</el-form-item>
				<el-form-item label="客户端类型" v-if="create_data.type === 0 || create_data.type === 2">
					<el-input v-model="create_data.clientType"></el-input>
				</el-form-item>
				<el-form-item label="最小版本号" v-if="create_data.type === 0">
					<el-input v-model="create_data.min"></el-input>
				</el-form-item>
				<el-form-item label="内部版本号" v-if="create_data.type === 2">
					<el-input v-model="create_data.version"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="save_data()">保存</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import Top from './common/Top'
import Menu from './common/Menu'
import axios from 'axios'
export default {
	name: 'SystemConfig',
	components: { Top, Menu },
	data() {
		return {
			menuIndex: '4',
			init: false,
			btn_saving: false,
			dialog_visible: false,
			system_config_data: {
				upgrade: false,
				appVersionFilters: [],
				grayType: 0,
				grayRadio: 50,
				grayVersions: [],
				grayUserIds: [],
				maxIpRequestRate: 500,
				maxUriRequestRate: 60,
				uriRequestRateConfig: [],
				uriWhiteList: [],
				uriBlackList: [],
				ipBlackList: [],
				ipWhiteList: []
			},
			search: {
				appVersionFilters: '',
				grayVersions: '',
				grayUserIds: '',
				uriRequestRateConfig: '',
				uriWhiteList: '',
				uriBlackList: '',
				ipBlackList: '',
				ipWhiteList: ''
			},
			create_data: {
				type: 0,
				appId: '',
				ip: '',
				uri: '',
				rate: 10,
				name: '',
				version: '',
				min: 0,
				clientType: 0
			}
		}
	},
	mounted() {
		this.load_gray()
	},
	computed: {
		show_table_appVersionFilters() {
			return this.system_config_data.appVersionFilters.filter((v) => {
				return v.isShow === undefined || v.isShow
			})
		},
		show_table_grayVersions() {
			return this.system_config_data.grayVersions.filter((v) => {
				return v.isShow === undefined || v.isShow
			})
		},
		show_table_grayUserIds() {
			return this.system_config_data.grayUserIds.filter((v) => {
				return v.isShow === undefined || v.isShow
			})
		},
		show_table_uriRequestRateConfig() {
			return this.system_config_data.uriRequestRateConfig.filter((v) => {
				return v.isShow === undefined || v.isShow
			})
		},
		show_table_uriWhiteList() {
			return this.system_config_data.uriWhiteList.filter((v) => {
				return v.isShow === undefined || v.isShow
			})
		},
		show_table_uriBlackList() {
			return this.system_config_data.uriBlackList.filter((v) => {
				return v.isShow === undefined || v.isShow
			})
		},
		show_table_ipBlackList() {
			return this.system_config_data.ipBlackList.filter((v) => {
				return v.isShow === undefined || v.isShow
			})
		},
		show_table_ipWhiteList() {
			return this.system_config_data.ipWhiteList.filter((v) => {
				return v.isShow === undefined || v.isShow
			})
		}
	},
	methods: {
		load_gray() {
			this.init = false
			this.axios_get('/gateway/api/config/').then((res) => {
				if (res.data.code === 0) {
					this.system_config_data = res.data.data
					this.init = true
					this.update_data()
				}
			})
		},
		on_key_word_change() {
			this.update_data()
		},
		show_create_dialog(type) {
			switch (type) {
				case 0:
					this.create_data.title = '创建App版本拦截规则'
					break
				case 1:
					this.create_data.title = '创建用户ID灰度规则'
					break
				case 2:
					this.create_data.title = '创建版本灰度规则'
					break
				case 3:
					this.create_data.title = '创建Uri频控策略'
					break
				case 4:
					this.create_data.title = '创建IP白名单'
					break
				case 5:
					this.create_data.title = '创建IP黑名单'
					break
				case 6:
					this.create_data.title = '创建Uri白名单'
					break
				case 7:
					this.create_data.title = '创建Uri黑名单'
					break
			}
			this.create_data.type = type
			this.dialog_visible = true
		},
		save_data() {
			switch (this.create_data.type) {
				case 0:
					this.system_config_data.appVersionFilters.push({ appId: this.create_data.appId, clientType: this.create_data.clientType, name: this.create_data.name, min: this.create_data.min })

					break
				case 1:
					this.system_config_data.grayUserIds.push({ userId: this.create_data.userId })
					break
				case 2:
					this.system_config_data.grayVersions.push({ appId: this.create_data.appId, name: this.create_data.name, version: this.create_data.version, clientType: this.create_data.clientType })
					break
				case 3:
					this.system_config_data.uriRequestRateConfig.push({ uri: this.create_data.uri, rate: this.create_data.rate })
					break
				case 4:
					this.system_config_data.ipWhiteList.push({ ip: this.create_data.ip })
					break
				case 5:
					this.system_config_data.ipBlackList.push({ ip: this.create_data.ip })
					break
				case 6:
					this.system_config_data.uriWhiteList.push({ uri: this.create_data.uri })
					break
				case 7:
					this.system_config_data.uriBlackList.push({ uri: this.create_data.uri })
					break
			}
			this.dialog_visible = false
		},
		update_data() {
			this.system_config_data.appVersionFilters.forEach((item, index) => {
				if (this.search.appVersionFilters === '') {
					item.isShow = true
				} else if (item.appId === this.search.appVersionFilters || (item.name && item.name.toLowerCase().indexOf(this.search.appVersionFilters) >= 0)) {
					item.isShow = true
				} else {
					item.isShow = false
				}
				this.$set(this.system_config_data.appVersionFilters, index, item)
			})

			this.system_config_data.grayVersions.forEach((item, index) => {
				if (this.search.grayVersions === '') {
					item.isShow = true
				} else if (item.appId === this.search.grayVersions || item.version === this.search.grayVersions || (item.name && item.name.toLowerCase().indexOf(this.search.grayVersions) >= 0)) {
					item.isShow = true
				} else {
					item.isShow = false
				}
				this.$set(this.system_config_data.grayVersions, index, item)
			})
			this.system_config_data.grayUserIds.forEach((item, index) => {
				if (this.search.grayUserIds === '') {
					item.isShow = true
				} else if (item.userId === this.search.grayUserIds) {
					item.isShow = true
				} else {
					item.isShow = false
				}
				this.$set(this.system_config_data.grayUserIds, index, item)
			})
			this.system_config_data.uriRequestRateConfig.forEach((item, index) => {
				if (this.search.uriRequestRateConfig === '') {
					item.isShow = true
				} else if (item.uri && item.uri.toLowerCase().indexOf(this.search.uriRequestRateConfig) >= 0) {
					item.isShow = true
				} else {
					item.isShow = false
				}
				this.$set(this.system_config_data.uriRequestRateConfig, index, item)
			})
			this.system_config_data.uriWhiteList.forEach((item, index) => {
				if (this.search.uriWhiteList === '') {
					item.isShow = true
				} else if (item.uri && item.uri.toLowerCase().indexOf(this.search.uriWhiteList) >= 0) {
					item.isShow = true
				} else {
					item.isShow = false
				}
				this.$set(this.system_config_data.uriWhiteList, index, item)
			})
			this.system_config_data.uriBlackList.forEach((item, index) => {
				if (this.search.uriBlackList === '') {
					item.isShow = true
				} else if (item.uri && item.uri.toLowerCase().indexOf(this.search.uriBlackList) >= 0) {
					item.isShow = true
				} else {
					item.isShow = false
				}
				this.$set(this.system_config_data.uriBlackList, index, item)
			})
			this.system_config_data.ipWhiteList.forEach((item, index) => {
				if (this.search.ipWhiteList === '') {
					item.isShow = true
				} else if (item.ip && item.ip.toLowerCase().indexOf(this.search.ipWhiteList) >= 0) {
					item.isShow = true
				} else {
					item.isShow = false
				}
				this.$set(this.system_config_data.ipWhiteList, index, item)
			})
			this.system_config_data.ipBlackList.forEach((item, index) => {
				if (this.search.uriBlackList === '') {
					item.isShow = true
				} else if (item.ip && item.ip.toLowerCase().indexOf(this.search.ipBlackList) >= 0) {
					item.isShow = true
				} else {
					item.isShow = false
				}
				this.$set(this.system_config_data.ipBlackList, index, item)
			})
		},
		onSubmit() {},
		deleteRow(index, rows) {
			rows.splice(index, 1)
			this.update_data()
		},
		on_modify_data_click() {
			this.btn_saving = true
			axios({
				url: `${this.base_api_uri}/gateway/api/config/`,
				method: 'POST',
				data: this.system_config_data,
				headers: {
					'Content-Type': 'application/json; charset=UTF-8'
				}
			})
				.then((res) => {
					this.btn_saving = false
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							duration: 3000,
							message: `更新配置信息成功`
						})
					} else {
						this.$message({
							type: 'error',
							duration: 3000,
							message: `更新配置信息失败，错误码:${res.data.code}`
						})
					}
				})
				.finally(() => {
					this.btn_saving = false
				})
		}
	}
}
</script>

<style scoped>
.main {
	height: 100%;
}
.container {
	height: calc(100% - 60px);
}
</style>